@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: var(--main-background-color);
}

html,
body,
#root {
    height: 100%;
}

#root {
    position: relative;
    overflow: hidden;
}

:root {
    color-scheme: dark;
}

@media (prefers-color-scheme: light) {
    :root {
        --main-background-color: rgba(247, 248, 251, 1);
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-background-color: #303030;
    }
}

:root {
    --1-6: 0.166666666666667; /* 6 */
    --1-4: 0.25;
    --1-3: 0.333333333333333; /* 3 */
    --1-2: 0.5;
    --3-4: 0.666666666666667; /* 1.5 */
    --5-6: 0.83333333333333333; /* 1.2 */
}

.label-letter.a {
    left: var(--P);
}

.label-letter.b {
    left: calc(var(--S) * var(--1-6) + var(--P));
}

.label-letter.c {
    left: calc(var(--S) * var(--1-3) + var(--P));
}

.label-letter.d {
    left: calc(var(--S) * var(--1-2) + var(--P));
}

.label-letter.e {
    left: calc(var(--S) * var(--3-4) + var(--P));
}

.label-letter.f {
    left: calc(var(--S) * var(--5-6) + var(--P));
}

.label-letter.g {
    left: calc(var(--S) + var(--P));
}

.nr1 {
    top: calc(var(--S) + var(--P));
}

.nr2 {
    top: calc(var(--S) * var(--5-6) + var(--P));
}

.nr3 {
    top: calc(var(--S) * var(--3-4) + var(--P));
}

.nr4 {
    top: calc(var(--S) * var(--1-2) + var(--P));
}

.nr5 {
    top: calc(var(--S) * var(--1-3) + var(--P));
}

.nr6 {
    top: calc(var(--S) * var(--1-6) + var(--P));
}

.nr7 {
    top: var(--P);
}

.a1 {
    top: var(--S);
    left: 0;
}

.a4 {
    top: calc(var(--S) * var(--1-2));
    left: 0;
}

.a7 {
    top: 0;
    left: 0;
}

.b2 {
    top: calc(var(--S) * var(--5-6));
    left: calc(var(--S) * var(--1-6));
}

.b4 {
    top: calc(var(--S) * var(--1-2));
    left: calc(var(--S) * var(--1-6));
}

.b6 {
    top: calc(var(--S) * var(--1-6));
    left: calc(var(--S) * var(--1-6));
}

.c3 {
    top: calc(var(--S) * var(--3-4));
    left: calc(var(--S) * var(--1-3));
}

.c4 {
    top: calc(var(--S) * var(--1-2));
    left: calc(var(--S) * var(--1-3));
}

.c5 {
    top: calc(var(--S) * var(--1-3));
    left: calc(var(--S) * var(--1-3));
}

.d1 {
    top: var(--S);
    left: calc(var(--S) * var(--1-2));
}

.d2 {
    top: calc(var(--S) * var(--5-6));
    left: calc(var(--S) * var(--1-2));
}

.d3 {
    top: calc(var(--S) * var(--3-4));
    left: calc(var(--S) * var(--1-2));
}

.d5 {
    top: calc(var(--S) * var(--1-3));
    left: calc(var(--S) * var(--1-2));
}

.d6 {
    top: calc(var(--S) * var(--1-6));
    left: calc(var(--S) * var(--1-2));
}

.d7 {
    top: 0;
    left: calc(var(--S) * var(--1-2));
}

.e3 {
    top: calc(var(--S) * var(--3-4));
    left: calc(var(--S) * var(--3-4));
}

.e4 {
    top: calc(var(--S) * var(--1-2));
    left: calc(var(--S) * var(--3-4));
}

.e5 {
    top: calc(var(--S) * var(--1-3));
    left: calc(var(--S) * var(--3-4));
}

.f2 {
    top: calc(var(--S) * var(--5-6));
    left: calc(var(--S) * var(--5-6));
}

.f4 {
    top: calc(var(--S) * var(--1-2));
    left: calc(var(--S) * var(--5-6));
}

.f6 {
    top: calc(var(--S) * var(--1-6));
    left: calc(var(--S) * var(--5-6));
}

.g1 {
    top: var(--S);
    left: var(--S);
}

.g4 {
    top: calc(var(--S) * var(--1-2));
    left: var(--S);
}

.g7 {
    top: 0;
    left: calc(var(--S));
}

.sizes {
    --v: 0.15;
    width: calc(var(--S) * var(--v));
    height: calc(var(--S) * var(--v));
    transform: translateX(calc(var(--S) * var(--v) * var(--1-2) * -1)) translateY(calc(var(--S) * var(--v) * var(--1-2) * -1));
}

@layer components {
    .wrapper {
        @apply relative overflow-hidden select-none;
        @apply max-h-full aspect-square m-auto;
    }

    .shadow-1 {
        @apply dark:before:border-[#212121];
        @apply before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0;
        @apply dark:before:border-t dark:before:border-l;
    }

    .shadow-2 {
        @apply dark:after:border-[#212121];
        @apply after:absolute after:left-0 after:-right-[2px] after:top-0 after:-bottom-[2px];
        @apply dark:after:border-b dark:after:border-r;
    }

    .square-out {
        @apply line shadow-1 shadow-2 border;
        width: var(--S);
        height: var(--S);
        top: var(--P);
        left: var(--P);
    }

    .square-mid {
        @apply line shadow-1 shadow-2 border;
        top: calc(var(--S) * var(--1-6));
        left: calc(var(--S) * var(--1-6));
        width: calc(var(--S) * var(--3-4));
        height: calc(var(--S) * var(--3-4));
    }

    .square-inner {
        @apply line shadow-1 shadow-2 border;
        top: calc(var(--S) * var(--1-3));
        left: calc(var(--S) * var(--1-3));
        width: calc(var(--S) * var(--1-3));
        height: calc(var(--S) * var(--1-3));
    }

    .line {
        @apply absolute box-content;
        @apply border-gray-900 dark:border-gray-500;
    }

    .line-left {
        @apply line shadow-1;
        @apply border-t h-0 w-1/3 top-1/2 left-0;
    }

    .line-right {
        @apply line shadow-1;
        @apply border-t h-0 w-1/3 top-1/2 left-2/3;
    }

    .line-top {
        @apply line shadow-2;
        @apply border-l h-1/3 w-0 top-0 left-1/2;
        @apply after:-right-0;
    }

    .line-bottom {
        @apply line shadow-2;
        @apply border-l h-1/3 w-0 top-2/3 left-1/2;
        @apply after:-right-0;
    }

    .dot {
        @apply absolute rounded-full drop-shadow sizes cursor-pointer z-10;
        @apply before:bg-gray-500 before:hover:bg-gray-700;
        @apply dark:before:bg-gray-400 dark:before:hover:bg-gray-700;
        @apply before:hover:w-1/3 before:hover:h-1/3 before:transition-all;
        @apply before:rounded-full;
        @apply before:absolute before:top-1/2 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2;
        @apply before:w-1/6 before:h-1/6;
        @apply dark:before:active:bg-gray-300 before:active:bg-gray-400;
    }

    .dot.animate {
        @apply animate-pulse bg-green-500/[.1] shadow hover:bg-green-500/[.5] hover:animate-none;
    }

    .label {
        @apply absolute rounded-full sizes;
        @apply flex justify-center items-center;
        @apply text-gray-500 dark:text-gray-400;
    }

    .label-number {
        @apply label;
        left: calc(var(--P) * var(--1-6));
        font-size: 1vmax;
    }

    .label-letter {
        @apply label;
        top: calc(var(--S) + var(--P) * (var(--1-2) + var(--1-6) / 1.5) * 3);
        font-size: 1vmax;
    }

    .label-hovered {
        @apply after:content-[''] after:absolute after:top-1/2 after:left-1/2;
        @apply after:bg-[#252525] after:rounded-full after:shadow after:-z-10 after:transition-colors;
        @apply after:w-1/4 after:h-1/4;
        @apply after:-translate-y-1/2 after:-translate-x-1/2
    }

    .chip {
        --shadow-color: rgba(0, 0, 0, .3);
        --size: 6.75;

        @apply z-20 absolute rounded-full -translate-y-1/2 -translate-x-1/2 drop-shadow-lg;
        @apply transition-all;
        @apply flex items-center justify-center;

        width: calc(var(--S) / var(--size));
        height: calc(var(--S) / var(--size));
        box-shadow: inset calc(var(--S) / -35) calc(var(--S) / -70) calc(var(--S) / 25) var(--shadow-color);
        background-image: linear-gradient(-45deg, var(--main-background-color) 0%, transparent 100%);
    }

    [class="chip-white"],
    [class="chip-black"] {
        @apply invisible;
    }

    .chip.selected {
        @apply border-4 border-green-800;
        --shadow-color: rgba(0, 0, 0, .5);
        --size: 8;
    }

    .w .chip-white,
    .b .chip-black {
        @apply cursor-pointer box-content active:drop-shadow-2xl;
    }

    .chip-white {
        @apply chip bg-gray-300;
        @apply top-1/2 left-1/2;
    }

    .chip-black {
        @apply chip bg-gray-900 dark:bg-gray-700;
        @apply top-1/2 left-1/2;
    }

    .delete-cross {
        @apply cursor-pointer;
        @apply before:w-2 before:h-2/3 before:bg-red-600 before:rounded-full;
        @apply before:absolute before:top-1/2 before:left-1/2 before:rotate-45;
        @apply before:-translate-x-1/2 before:-translate-y-1/2;
        @apply after:w-2 after:h-2/3 after:bg-red-600 after:rounded-full;
        @apply after:absolute after:top-1/2 after:left-1/2 after:rotate-135;
        @apply after:-translate-x-1/2 after:-translate-y-1/2;
    }

    .side {
        @apply absolute rounded-full drop-shadow-lg;
        @apply invisible opacity-0 transition-all;

        --v: 0.2;

        width: calc(var(--S) * var(--v));
        height: calc(var(--S) * var(--v));
        top: calc(var(--S) * var(--v) / -2);
    }

    .side-w {
        @apply side bg-gray-300;
        left: calc(var(--S) * var(--v) / -2);
    }

    .side-b {
        @apply side bg-gray-900;
        right: calc(var(--S) * var(--v) / -2);
    }

    .message {
        @apply absolute -translate-x-1/2 -translate-y-1/2;
        @apply flex flex-col items-center justify-center text-center;

        top: calc(var(--S) * var(--1-2) + var(--P));
        left: calc(var(--S) * var(--1-2) + var(--P));
        width: calc(var(--S) * var(--1-4));
        height: calc(var(--S) * var(--1-4));
    }

    .msg {
        @apply p-3 rounded;
        font-size: calc(var(--S) * 0.02);
    }

    .message .note {
        font-size: calc(var(--S) * 0.02);
    }

    .message .white {
        @apply msg bg-gray-300 text-gray-800 mb-2;
    }

    .message .black {
        @apply msg bg-gray-900 text-gray-300 mb-2;
    }
}
